<!--选择景区-->
<template>
  <div class="select-container">
    <div class="content">
      <header class="header bd-b">
        <div class="flex-start align-center">
          <p class="bold primary font-xxl">畅游宝</p>
        </div>

        <van-popover
          v-model="showPopover"
          trigger="click"
          :actions="actions"
          @select="handleCommand"
        >
          <template #reference>
            <div class="flex-start align-center pointer">
              <van-image
                round
                width="1rem"
                height="1rem"
                :src="userInfo.headImgUrl"
              />
              <div class="mh-1x">
                <p class="font-s">{{ userInfo.nickName }}</p>
                <p>{{ account | safeShow }}</p>
              </div>
              <van-icon :name="showPopover ? 'arrow-up' : 'arrow-down'" />
            </div>
          </template>
        </van-popover>
      </header>
      <!--列表-->
      <van-row>
        <van-col
          span="24"
          class="mt-2x"
          v-for="(item, index) in list"
          :key="index"
          @click="linkTo(item)"
        >
          <div class="card">
            <div class="flex-between align-center">
              <h2 class="normal text-1">{{ item.merchantName }}</h2>
              <p class="font-l primary">{{ item.merchantTypeText }}</p>
            </div>
            <p class="mt-3x font-l gray" v-if="item.merchantExpireTime">
              创建日期：{{ item.createTime | date }}
            </p>
            <p class="mt-1x font-l gray" v-if="item.merchantExpireTime">
              到期日期：{{ item.merchantExpireTime | date }}
            </p>
            <i class="iconfont icon-liangting links-icon"></i>
          </div>
        </van-col>
        <a-col :lg="{ span: 12 }" :xl="{ span: 8 }" v-if="noData">
          <div class="card pointer text-c gray add">
            <span style="font-size: 24px; line-height: 132px"
              >暂无可操作景区</span
            >
          </div>
        </a-col>
      </van-row>
    </div>
    <!--修改密码弹窗-->
    <change-pass-word
      :show.sync="show"
      :user-info="userInfo"
    ></change-pass-word>
  </div>
</template>

<script>
import { findCurrentUserRelationMerchantList } from "../../api/merchant";
import { authorize, getUserInfo, logout } from "../../api/user";
import { phoneFormat } from "../../utils/global";
import ChangePassWord from "./components/ChangePassWord";

export default {
  name: "SelectScenic",
  components: { ChangePassWord },
  data() {
    return {
      account: localStorage.getItem("account"),
      userInfo: {},
      list: [],
      noData: false,
      showPopover: false,
      actions: [
        { text: "修改密码", icon: "edit" },
        { text: "退出登录", icon: "guide-o" },
      ],
      show: false, // 修改密码弹窗
    };
  },
  created() {
    this.loadData();
    this.loadUser();
  },
  filters: {
    safeShow: phoneFormat,
  },
  methods: {
    // 加载组织列表
    loadData() {
      findCurrentUserRelationMerchantList()
        .then((res) => {
          // merchantType-商户类型：1趣买票，2服务商（趣买票代理商），3集团，4景区，5游小铺，6园内商户，7周边商户，8分销商，9OTA，10零售供应商
          this.list = res.filter((item) => item.merchantType === 4);
          this.noData = this.list.length === 0;
        })
        .catch(() => {});
    },
    // 加载用户信息
    loadUser() {
      getUserInfo()
        .then((res) => {
          this.userInfo = res;
          if (!res.ifSetPwd) this.show = true;
        })
        .catch(() => {});
    },
    handleCommand(action) {
      if (action.text === "修改密码") {
        this.show = true;
      } else {
        this.$dialog
          .confirm({
            title: "提示",
            message: "确定要退出登录吗？",
          })
          .then(() => {
            logout()
              .then(() => {
                this.$toast.success("已退出登录");
              })
              .catch(() => {})
              .finally(() => {
                localStorage.removeItem("toke");
                localStorage.removeItem("account");
                this.$router.push("/login");
              });
          })
          .catch(() => {});
      }
    },
    // 跳转到首页
    linkTo(item) {
      authorize({
        merchantId: item.merchantId,
        channel: 7, // 手持机7
      })
        .then(() => {
          localStorage.setItem("merchantId", item.merchantId); // 商户id，下次登录不用选择平台
          localStorage.setItem("userId", item.userId); // 用户id，获取用户信息
          this.$router.push("/");
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="less">
.select-container {
  .content {
    max-width: 1300px;
    margin: 0 auto;
    padding-bottom: 24px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: sticky;
      top: 0;
      left: 0;
      padding: 0.32rem;
      background: #fff;
      z-index: 2;
    }

    .card {
      height: 3.2rem;
      position: relative;
      border-top: 4px solid @primary;
      border-radius: 0.16rem;
      cursor: pointer;

      .links-icon {
        position: absolute;
        right: 0.32rem;
        bottom: 0.32rem;
        font-size: 0.64rem;
        color: @primary;
      }
      /*添加体验用户*/
      &.add {
        border: 1px solid @border;
      }
    }
  }
}
</style>
