<template>
  <!-- 登录主体 -->
  <div class="login-main">
    <!-- logo -->
    <div class="login-logo">
      <img
        src="https://local-qmp.oss-cn-shenzhen.aliyuncs.com/AppDatas/16486/1648691201624.png"
        class="logo"
      />
    </div>
    <div class="login-centent">
      <div class="login-title">登录趣买票手持终端</div>
      <van-form @submit="loginSubmit" ref="loginForm">
        <div class="login-input">
          <van-field
            :style="{ 'border-bottom': '1px solid #cbcfd2' }"
            name="mobile"
            clearable
            v-model="loginForm.mobile"
            placeholder="请输入账号"
            :rules="[{ required: true, message: '请输入账号' }]"
          />
        </div>
        <!-- 密码输入框 -->
        <div class="login-input" v-if="loginType === 2">
          <van-field
            :style="{ 'border-bottom': '1px solid #cbcfd2' }"
            name="password"
            type="password"
            clearable
            v-model="loginForm.password"
            placeholder="请输入密码"
            :rules="[{ required: true, message: '请输入密码' }]"
          />
        </div>
        <!-- 验证码输入框 -->
        <div class="login-input" v-if="loginType === 3">
          <van-field
            :style="{ 'border-bottom': '1px solid #cbcfd2' }"
            v-model="loginForm.validateCode"
            center
            clearable
            name="validateCode"
            placeholder="请输入短信验证码"
            :rules="[
              { required: true, message: '请输入短信验证码' },
              { validator, message: '验证码为6位数字' },
            ]"
          >
            <template #button>
              <van-button
                size="small"
                class="get-code"
                round
                :style="{ borderColor: color, color: color }"
                @click="getCode"
              >
                {{ tips }}
              </van-button>
            </template>
          </van-field>
        </div>
        <div class="flex-between login-method">
          <template v-if="loginType === 2">
            <div class="blue" @click="changeLoginType(3)">验证码登录</div>
          </template>
          <template v-if="loginType === 3">
            <div class="blue" @click="changeLoginType(2)">账号密码登录</div>
          </template>
          <van-button
            type="info"
            native-type="submit"
            class="login-btn"
            :style="{ background: color }"
          >
            登录
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { encrypt } from "../../utils/crypt-aes";
import { authorize, login, smsCodeLogin } from "../../api/user";
import { messageVerifyCode } from "../../api/message";
import { findCurrentUserRelationMerchantList } from "../../api/merchant";
import { phoneReg } from "../../utils/global";

export default {
  name: "LoginIndex",
  data() {
    return {
      loading: false,
      loginForm: {
        mobile: "",
        password: "",
        validateCode: "",
      },
      loginType: 2, // 登录方式
      tips: "获取验证码",
      canGetCode: true, // 是否可以再次发送
      timer: null, // 定时器
    };
  },
  computed: {
    // 主题配色变量
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },
  created() {
    if (localStorage.token) {
      this.$router.push("/");
    }
  },
  methods: {
    validator(val) {
      return /^[0-9]{6}$/.test(val);
    },
    codeChange(text) {
      this.tips = text;
    },
    getCode() {
      if (!phoneReg.test(this.loginForm.mobile)) {
        this.$toast("请输入正确的手机号");
        return;
      }
      if (this.canGetCode) {
        messageVerifyCode({
          verifyCodeType: "LOGIN",
          objectPhone: this.loginForm.mobile,
        })
          .then(() => {
            this.canGetCode = false;
            // 模拟向后端请求验证码
            this.$toast.loading({
              message: "正在获取验证码",
              forbidClick: true,
            });
            let times = 120;
            clearTimeout(this.timer);
            this.timer = setInterval(() => {
              times -= 1;
              this.tips = times + "秒重发";
              if (times === 0) {
                clearInterval(this.timer);
                this.timer = null;
                this.tips = "重新获取";
                this.canGetCode = true;
              }
            }, 1000);
          })
          .catch(() => {});
      } else {
        this.$toast("倒计时结束后再发送");
      }
    },
    // 切换登录方式
    changeLoginType(type) {
      this.loginType = type;
    },
    loginSubmit() {
      this.$refs.loginForm
        .validate()
        .then(() => {
          if (this.loading) return;
          // 账号密码登录
          if (this.loginType === 2) {
            this.loading = true;
            // 加密处理，获得加密后的秘钥和密文
            const { key, encryptedWord } = encrypt(this.loginForm.password);
            login(
              {
                phone: this.loginForm.mobile,
                password: encryptedWord,
                passwordKey: key,
              },
              { loading: false }
            )
              .then(() => {
                this.linkTo(this.loginForm.mobile);
              })
              .catch(() => {
                this.loading = false;
              });
          } else {
            // 验证码登录
            smsCodeLogin({
              phone: this.loginForm.mobile,
              code: this.loginForm.validateCode,
            })
              .then(() => {
                this.linkTo(this.loginForm.mobile);
              })
              .catch(() => {
                this.loading = false;
              });
          }
        })
        .catch(() => {});
    },
    // 回跳
    linkTo(account) {
      localStorage.setItem("account", account);
      // 获取景区/商户列表，如果有则授权跳转
      findCurrentUserRelationMerchantList()
        .then((res) => {
          // 过滤出景区
          const arr = res.filter((item) => item.channel === 4);
          if (arr.length === 1) {
            localStorage.setItem("merchantId", arr[0].merchantId); // 商户id，下次登录不用选择平台
            localStorage.setItem("userId", arr[0].userId); // 用户id，获取用户信息
            this.toAuthorize(arr[0].merchantId);
          } else if (arr.length > 1) {
            // uni.$u.route("/pages/index/merchant-list");
            this.$router.push("/login/MerchantList");
          } else {
            this.$toast("未查询到授权的景区");
          }
        })
        .catch(() => {});
    },
    // 授权
    toAuthorize(id) {
      authorize({
        merchantId: id,
        channel: 7,
      })
        .then(() => {
          this.$router.push("/");
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="less">
.login-main {
  height: 100vh;
  background: #fff;
  /* 登录样式 */
  .login-logo {
    padding: 115px 10px 0px;
    .logo {
      width: 176px;
      height: 70px;
    }
  }
  .login-centent {
    padding: 0px 27px;
    .login-title {
      color: #8a8a8a;
      font-size: 16px;
      margin-top: 5px;
    }
    .login-input {
      margin-top: 40px;
      line-height: 40px;
      height: 40px;
    }
    .login-method {
      margin-top: 70px;
    }
    .login-btn {
      width: 130px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      color: #fff;
      border-radius: 25px 25px 25px 25px;
      background: @primary;
      font-size: 16px;
      font-weight: bold;
      border: none;
    }
    .get-code {
      width: 100px;
      height: 40px;
      line-height: 40px;
      font-size: 12px;
    }
  }
}
</style>
