<template>
  <div class="login">
    <!--登录内容-->
    <main class="main">
      <!--密码登录-->
      <header class="header-title">
        <div class="flex-start" v-show="loginType !== 1">
          <h3
            :class="{ 'toggle-login': loginType === 3 }"
            @click="changeLoginType(2)"
          >
            密码登录
          </h3>
          <h3
            class="ml-2x"
            :class="{ 'toggle-login': loginType === 2 }"
            @click="changeLoginType(3)"
          >
            验证码登录
          </h3>
        </div>
        <h3 class="scan" v-show="loginType === 1">扫码登录</h3>
      </header>
      <!--切换扫码登录-->
      <div class="toggle-qrcode pointer">
        <!--<img-->
        <!--  src="../../assets/images/login-toggle-code.png"-->
        <!--  alt=""-->
        <!--  v-show="loginType !== 1"-->
        <!--  @click="changeLoginType(1)"-->
        <!--/>-->
        <!-- <i
          class="iconfont icon-erweimadenglu"
          v-show="loginType !== 1"
          @click="changeLoginType(1)"
        ></i> -->
        <!--<img-->
        <!--  src="../../assets/images/login-toggle-pclogin.png"-->
        <!--  alt=""-->
        <!--  @click="changeLoginType(2)"-->
        <!--  v-show="loginType === 1"-->
        <!--/>-->
        <i
          class="iconfont icon-mimadenglu"
          @click="changeLoginType(2)"
          v-show="loginType === 1"
        ></i>
      </div>
      <!--登录表单-->
      <van-form
        ref="loginForm"
        v-show="loginType !== 1"
        @submit="loginSubmit"
        class="ruleForm"
      >
        <van-field
          size="large"
          name="手机号/账号"
          v-model="loginForm.mobile"
          placeholder="请输入手机号码/账号"
          clearable
          :rules="loginRules.mobile"
        ></van-field>
        <van-field
          size="large"
          type="password"
          name="密码"
          v-if="loginType === 2"
          v-model="loginForm.password"
          placeholder="请输入密码"
          clearable
          @pressEnter="loginSubmit"
          :rules="loginRules.password"
        ></van-field>
        <van-field
          size="large"
          v-if="loginType === 3"
          v-model="loginForm.validateCode"
          center
          clearable
          name="短信验证码"
          placeholder="请输入短信验证码"
          native-type="button"
          :rules="loginRules.validateCode"
        >
          <template #button>
            <van-button
              size="small"
              type="primary"
              v-if="time === 60"
              @click="getValidateCode('loginForm')"
              >发送验证码</van-button
            >
            <span class="font-l" v-else>
              <van-icon name="underway-o" /> {{ time }}s
            </span>
          </template>
        </van-field>
        <!--提交按钮-->
        <div class="mt-4x">
          <van-button
            round
            block
            type="primary"
            native-type="submit"
            :loading="loading"
            >提交</van-button
          >
        </div>
      </van-form>
      <!--二维码-->
      <div v-show="loginType === 1" class="qr-code">
        <div class="relative">
          <img :src="qrUrl" alt="" class="img" />
          <div class="module" v-show="hadScan">
            <van-icon name="checked" class="primary el-icon-success" />
            <p class="font-l mt-1x">扫码成功</p>
          </div>
          <div class="module disabled" v-show="refresh && !pending">
            <van-icon name="clear" class="disabled el-icon-error" />
            <p class="font-l mt-1x">已失效</p>
          </div>
          <!--加载中-->
          <div class="loading flex-center" v-show="pending">
            <van-loading type="spinner" vertical>加载中...</van-loading>
          </div>
        </div>
        <!--未扫码-二维码失效-->
        <div v-show="!hadScan">
          <p class="font-l">
            <van-icon name="wechat" class="text-v font-xl" color="#09bb07" />
            <span class="text-v ml-1x">微信扫一扫</span>
          </p>
          <div
            class="flex-center align-center font-l red mt-x pointer"
            v-show="refresh && qrUrl && !pending"
            @click="getQrCode"
          >
            <p class="mr-x">二维码已失效，刷新</p>
            <van-icon name="replay" class="font-xl" />
          </div>
          <p class="font-l mt-x" v-show="!refresh || pending">
            关注公众号注册登录
          </p>
        </div>
        <!--扫码成功-重新扫码-->
        <div v-show="hadScan">
          <p class="font-l">
            <van-icon name="wechat" class="text-v" color="#09bb07" />
            <span class="text-v ml-1x">小程序一键登录</span>
          </p>
          <div
            class="flex-center align-center font-l blue mt-x pointer"
            @click="reScan"
          >
            <p class="mr-x">重新扫码</p>
            <van-icon name="replay" style="font-size: 18px" />
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { loginMessageVerifyCode } from "../../api/message";
import { login, authorize, smsCodeLogin } from "../../api/user";
import { phoneReg, checkNormal } from "../../utils/global";
import { encrypt } from "../../utils/crypt-aes";
import { findCurrentUserRelationMerchantList } from "../../api/merchant";
import {
  wxGuideQrCode,
  wxUserOpenIdMobile,
  wxSubScribe,
} from "../../api/applet";
export default {
  name: "Login",
  data() {
    return {
      loginType: 2,
      loading: false, // 表单提交中
      time: 60, // 验证码倒计时
      timerOut: null, //二维码失效一次性定时器
      timerOut2: null, //扫码二维码轮询结果一次性定时器
      timerIn: null, // 验证码定时器
      // 扫码登录
      scenStr: "", // 扫码登录的随机数
      qrUrl: "",
      pending: false, // 二维码加载中
      refresh: true, // 刷新二维码
      hadScan: false, // 是否已经扫码
      openId: "",

      // 登录表单
      loginForm: {
        mobile: "",
        password: "",
        validateCode: "",
      },
      loginRules: {
        mobile: [
          { required: true, message: "请输入手机号码/账号" },
          { ...checkNormal },
        ],
        password: [{ required: true, message: "请输入密码" }],
        validateCode: [
          { required: true, message: "请输入验证码" },
          { pattern: /^[0-9]{6}$/, message: "验证码为6位数字" },
        ],
      },
    };
  },
  created() {
    // this.getQrCode();
  },
  beforeDestroy() {
    this.refresh = true;
  },
  methods: {
    // 切换登录方式
    changeLoginType(type) {
      this.clearTimerOut();
      this.loginType = type;
      this.hadScan = false;
      this.refresh = true;
      if (type === 1) this.getQrCode();
      this.$refs.loginForm.resetValidation();
    },
    // 获取二维码
    getQrCode() {
      if (this.refresh) {
        this.pending = true;
        this.scenStr = new Date().getTime() + Math.random() + ""; // 随机数
        wxGuideQrCode(
          {
            qrcode_info: this.scenStr,
          },
          {
            loading: false,
          }
        )
          .then(({ qrUrl }) => {
            this.qrUrl = qrUrl;
            this.refresh = false;
            this.scanned();
            this.setTimerOut();
          })
          .catch(() => {})
          .finally(() => {
            this.pending = false;
          });
      }
    },
    // 设置一次性定时器
    setTimerOut() {
      this.timerOut = setTimeout(() => {
        this.refresh = true;
        this.clearTimerOut();
      }, 60000);
    },
    // 清除一次性定时器
    clearTimerOut() {
      clearTimeout(this.timerOut);
      clearTimeout(this.timerOut2);
      this.timerOut = null;
      this.timerOut2 = null;
    },
    // 重新扫码
    reScan() {
      this.hadScan = false;
      this.refresh = true;
      this.clearTimerOut();
      this.getQrCode();
    },
    // 获取是否扫码关注微信公众号
    scanned() {
      wxSubScribe(
        {
          scene_str: this.scenStr,
          t: new Date().getTime(),
        },
        { loading: false }
      )
        .then(({ openId }) => {
          if (openId) {
            this.hadScan = true;
            this.getWxLogin(openId);
          }
          // 如果二维码没有失效且定时器不存在,因为频繁切换登录方式，网络延迟，会存在多过定时器
          else if (!this.refresh && !this.timerOut2) {
            this.timerOut2 = setTimeout(() => {
              this.scanned();
              clearTimeout(this.timerOut2);
              this.timerOut2 = null;
            }, 2000);
          }
        })
        .catch(() => {});
    },
    // 获取是否注册成功，成功则头部header得到token
    getWxLogin(openId) {
      wxUserOpenIdMobile({ openId }, { loading: false })
        .then(({ code }) => {
          if (code !== "FAIL") {
            this.linkTo(code);
          } else if (!this.refresh && this.hadScan) {
            this.timerOut2 = setTimeout(() => {
              this.getWxLogin(openId);
              clearTimeout(this.timerOut2);
              this.timerOut2 = null;
            }, 1000);
          }
        })
        .catch(() => {});
    },
    // 获取验证码
    getValidateCode(form) {
      if (!phoneReg.test(this[form].mobile)) {
        this.$toast.fail("请输入正确的手机号");
        return;
      }
      loginMessageVerifyCode({
        verifyCodeType: "LOGIN",
        objectPhone: this[form].mobile,
      })
        .then(() => {
          this.setTimerIn();
        })
        .catch(() => {});
    },
    // 验证码定时器
    setTimerIn() {
      this.time--;
      this.timerIn = setInterval(() => {
        this.time--;
        if (this.time <= 0) {
          this.clearTimerIn();
        }
      }, 1000);
    },
    // 去除二维码失效和扫码轮询结果定时器
    clearTimerIn() {
      clearInterval(this.timerIn);
      this.timerIn = null;
      this.time = 60;
    },
    // 登录按钮提交
    loginSubmit() {
      // 密码登录
      if (this.loginType === 2) {
        this.loading = true;
        // 加密处理，获得加密后的秘钥和密文
        const { key, encryptedWord } = encrypt(this.loginForm.password);
        login(
          {
            phone: this.loginForm.mobile,
            password: encryptedWord,
            passwordKey: key,
          },
          { loading: false }
        )
          .then(() => {
            this.linkTo(this.loginForm.mobile);
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        // 验证码登录
        smsCodeLogin({
          phone: this.loginForm.mobile,
          code: this.loginForm.validateCode,
        })
          .then(() => {
            this.linkTo(this.loginForm.mobile);
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    // 跳转到首页
    linkTo(account) {
      this.refresh = true; // 停止刷新
      this.clearTimerIn();
      /**
       *@name:跳转方法
       *@param: { String } url要跳转的路由，默认选择平台页面
       */
      const go = (url) => {
        this.loading = false;
        localStorage.setItem("account", account);
        this.$toast.success("登录成功");
        if (url) this.$router.replace(url);
        else this.$router.back();
      };
      // 如果登录过选择过景区id
      if (localStorage.merchantId || sessionStorage.merchantId) {
        findCurrentUserRelationMerchantList()
          .then((res) => {
            const userAuthorize = (merchantId, channel) => {
              const obj = {
                merchantId,
                channel,
              };
              if (sessionStorage.saleMerchantId)
                obj.saleMerchantId = sessionStorage.saleMerchantId;
              authorize(obj)
                .then(() => {
                  // 重新登录返回指定
                  if (this.$route.query.redirect) {
                    go(this.$route.query.redirect);
                  } else {
                    // 返回上一个页面
                    go();
                  }
                })
                .catch(() => {
                  go("/login/SelectScenic");
                });
            };
            // 判断该账户是否存在该景区
            let temp = res.find(
              (item) => item.merchantId === localStorage.merchantId
            );
            if (temp) {
              localStorage.setItem("userId", temp.userId);
              // 跳转之前选择的景区 或者之前选中的商户页面
              userAuthorize(localStorage.merchantId, 7);
            } else if (res.length === 1) {
              // 只有一个景区直接跳转该景区
              userAuthorize(res[0].merchantId, 7);
              localStorage.setItem("userId", res[0].userId);
            } else {
              go("/login/SelectScenic");
            }
          })
          .catch(() => {});
      }
      // 新用户，直接跳转选择平台页面
      else {
        localStorage.removeItem("merchantId");
        localStorage.removeItem("channel");
        go("/login/SelectScenic");
      }
    },
  },
};
</script>

<style scoped lang="less">
.login {
  position: relative;
  height: 100vh;
  background: url("../../assets/images/login-bg.png");
  background-size: 100% 100%;
  .main {
    width: 6rem;
    height: 6.5rem;
    box-sizing: border-box;
    padding: 0.4rem;
    border-radius: 0.16rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
    font-size: 0.32rem;

    .header-title {
      h2 {
        font-weight: normal;
        font-size: 0.56rem;
      }
      h2:not(.scan):hover {
        cursor: pointer;
        color: @primary;
      }
      .toggle-login {
        align-self: flex-end;
        font-weight: 400;
        font-size: 0.32rem;
        color: @gray;
      }
    }

    .toggle-qrcode {
      position: absolute;
      top: 0.16rem;
      right: 0.3rem;
      .iconfont {
        font-size: 0.96rem;
        color: @primary;
      }
    }
    /*登录表单*/
    .ruleForm {
      margin-top: 0.4rem;
      padding-top: 0.4rem;
    }
    /*二维码*/
    .qr-code {
      margin-top: 0.4rem;
      text-align: center;
      .img {
        width: 3rem;
        height: 3rem;
      }
      .module {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.98);
        .el-icon-success,
        .el-icon-error {
          margin-top: 0.3rem;
          font-size: 1.8rem;
        }
      }

      .loading {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 3rem;
        height: 3rem;
        background: rgba(255, 255, 255, 0.98);
      }
    }
  }
}
</style>
