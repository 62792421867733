<!--修改密码-->
<template>
  <van-dialog :value="show" title="修改密码" :show-confirm-button="false">
    <van-form @submit="onSubmit" class="mt-2x">
      <van-field
        v-if="userInfo.ifSetPwd"
        v-model="oldPassword"
        type="password"
        name="原密码"
        label="原密码"
        placeholder="请输入原密码"
        :rules="[{ required: true, message: '请输入确认密码' }]"
        clearable
      />
      <van-field
        clearable
        v-model="password"
        type="password"
        name="新密码"
        label="新密码"
        placeholder="请输入新密码（字母/数字）"
        :rules="[
          { required: true, message: '请输入确认密码' },
          { pattern: /^\w{8,16}$/, message: '密码为数字和字母的组合，8~16位' },
        ]"
      />
      <van-field
        clearable
        v-model="password2"
        type="password"
        name="确认密码"
        label="确认密码"
        placeholder="请输入确认密码（字母/数字）"
        :rules="[
          { required: true, message: '请输入确认密码' },
          { pattern: /^\w{8,16}$/, message: '密码为数字和字母的组合，8~16位' },
          {
            validator: validatePasswordISOK,
            message: '新密码与确认密码不一致',
          },
        ]"
      />
      <div class="p-2x">
        <van-button
          round
          block
          type="primary"
          native-type="submit"
          :loading="loading"
          >提交</van-button
        >
      </div>
    </van-form>
    <div class="close" @click="close">
      <van-icon name="close" class="font-xxl gray" />
    </div>
  </van-dialog>
</template>

<script>
import { checkNormal } from "../../../utils/global";
import { setPassword } from "../../../api/user";
import { encrypt } from "../../../utils/crypt-aes";
export default {
  name: "ChangePassWord",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    userInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      oldPassword: "",
      password: "",
      password2: "",
      loading: false,
      checkNormal,
    };
  },
  methods: {
    // 校验新旧密码
    validatePasswordISOK(val) {
      return this.password === val;
    },
    onSubmit() {
      this.loading = true;
      // 加密
      const obj1 = encrypt(this.oldPassword);
      const obj2 = encrypt(this.password);
      setPassword({
        phone: this.userInfo.phone,
        oldPwdKey: obj1.key,
        oldPwd: obj1.encryptedWord,
        newPwdKey: obj2.key,
        newPwd: obj2.encryptedWord,
      })
        .then(() => {
          this.$dialog
            .confirm({
              title: "提示",
              message: "修改成功，是否重新登录？",

              confirmButtonText: "重新登录",
            })
            .then(() => {
              this.toLogin();
            })
            .catch(() => {});
          this.close();
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.oldPassword = "";
      this.password = "";
      this.password2 = "";
      this.$emit("update:show", false);
    },
    // 去登录
    toLogin() {
      localStorage.removeItem("toke");
      localStorage.removeItem("account");
      this.$router.replace("/login");
    },
  },
};
</script>

<style scoped>
.close {
  position: absolute;
  top: 0.32rem;
  right: 0.32rem;
}
</style>
