var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('van-dialog',{attrs:{"value":_vm.show,"title":"修改密码","show-confirm-button":false}},[_c('van-form',{staticClass:"mt-2x",on:{"submit":_vm.onSubmit}},[(_vm.userInfo.ifSetPwd)?_c('van-field',{attrs:{"type":"password","name":"原密码","label":"原密码","placeholder":"请输入原密码","rules":[{ required: true, message: '请输入确认密码' }],"clearable":""},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}}):_vm._e(),_c('van-field',{attrs:{"clearable":"","type":"password","name":"新密码","label":"新密码","placeholder":"请输入新密码（字母/数字）","rules":[
        { required: true, message: '请输入确认密码' },
        { pattern: /^\w{8,16}$/, message: '密码为数字和字母的组合，8~16位' } ]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('van-field',{attrs:{"clearable":"","type":"password","name":"确认密码","label":"确认密码","placeholder":"请输入确认密码（字母/数字）","rules":[
        { required: true, message: '请输入确认密码' },
        { pattern: /^\w{8,16}$/, message: '密码为数字和字母的组合，8~16位' },
        {
          validator: _vm.validatePasswordISOK,
          message: '新密码与确认密码不一致',
        } ]},model:{value:(_vm.password2),callback:function ($$v) {_vm.password2=$$v},expression:"password2"}}),_c('div',{staticClass:"p-2x"},[_c('van-button',{attrs:{"round":"","block":"","type":"primary","native-type":"submit","loading":_vm.loading}},[_vm._v("提交")])],1)],1),_c('div',{staticClass:"close",on:{"click":_vm.close}},[_c('van-icon',{staticClass:"font-xxl gray",attrs:{"name":"close"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }