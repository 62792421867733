<template>
  <div class="memchant-centent">
    <img
      src="https://local-qmp.oss-cn-shenzhen.aliyuncs.com/AppDatas/16486/1648691120546.png"
      class="bg-img"
    />
    <div class="relative">
      <div class="memchant-main mb-4x">
        <div class="memchant-title">选择景区</div>
        <div class="list">
          <div
            v-for="(item, idx) in merchantList"
            :key="item.merchantId"
            :index="idx"
            class="item relative"
            :class="{ selected: itemId === idx }"
            :style="{ 'border-color': itemId === idx ? color : '' }"
            @click="changeMemchant(idx)"
          >
            <span>{{ item.merchantName }}</span>
            <img
              v-if="itemId === idx"
              src="../../assets/images/record/dui@2x.png"
              class="selected-img"
            />
          </div>
        </div>
      </div>
    </div>

    <footer class="flex-center footer">
      <van-button type="primary" block round @click="toAuthorize">
        进入
      </van-button>
    </footer>
  </div>
</template>

<script>
import { findCurrentUserRelationMerchantList } from "../../api/merchant";
import { authorize } from "../../api/user";
export default {
  name: "MerchantList",
  data() {
    return {
      itemId: 0, //选中的景区下标
      merchantList: [], // 景区列表
    };
  },
  created() {
    this.getMerchantList();
  },
  computed: {
    // 主题配色变量
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },
  methods: {
    // 选中景区
    changeMemchant(id) {
      this.itemId = id;
    },
    // 获取景区列表
    getMerchantList() {
      findCurrentUserRelationMerchantList()
        .then((res) => {
          this.merchantList = res.filter((item) => item.channel === 4);
        })
        .catch(() => {});
    },
    // 授权
    toAuthorize() {
      let item = this.merchantList[this.itemId];
      let id = this.merchantList[this.itemId].merchantId;
      authorize({
        merchantId: id,
        channel: 7, // 手持机7
      })
        .then(() => {
          localStorage.setItem("merchantId", item.merchantId); // 商户id，下次登录不用选择平台
          localStorage.setItem("userId", item.userId); // 用户id，获取用户信息
          this.$router.push("/");
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.memchant-centent {
  .bg-img {
    width: 100%;
    height: 215px;
  }
  .memchant-main {
    padding-bottom: 76px;
    background: #ffffff;
    border-radius: 9px 9px 9px 9px;
    position: absolute;
    left: 10px;
    right: 10px;
    top: -20px;

    .memchant-title {
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      padding: 17px 0px;
    }
    .list {
      padding: 0px 17px;
      .item {
        height: 60px;
        line-height: 60px;
        border-radius: 7px 7px 7px 7px;
        border: 1px solid #d8dbdd;
        margin-bottom: 11px;
        font-size: 16px;
        font-weight: 400;
        padding: 0px 16px;
        .selected-img {
          width: 17px;
          height: 17px;
          position: absolute;
          right: 17px;
          top: 21px;
        }
      }

      .selected {
        box-shadow: 2px 2px 4px 1px #d9e6e1;
        border: 1px solid #3bd591;
      }
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 12px 16px;
  }
}
</style>
